/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* div[class*="row"] {
    outline: 5px dotted rgba(0, 0, 0, 0.25);
}

div[class*="col-"] {
    background-color: rgba(255, 0, 0, 0.2);
    outline: 5px dotted rgba(0, 0, 0, 0.5);
} */
